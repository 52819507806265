/*eslint-disable no-unused-vars*/
import React, { Component } from "react";
/*eslint-enable no-unused-vars*/
import { CookieModal } from "./Modals";
import { setCookie, getCookie } from "../genericFunctions";
import { CookieTypes, CookiesArr } from "../constants";
import { connect } from "react-redux";
import { saveCookiesSettings, clearCookiesSettings } from "../actions/cookies";

const Field = props => (
  <div className="checkbox-box">
    <input
      onChange={props.onChange}
      defaultChecked={props.checked}
      disabled={props.disabled}
      type="checkbox"
      className="form-control"
      id={props.name}
      name={props.name}
    />
    <label htmlFor={props.name} className="required">
      {props.label}
    </label>
  </div>
);

class CookieConsentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      functionalCookies: true,
      analyticalCookies: false,
      promotionalCookies: false,
      preferencesCookies: false
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const newState = prevState;
    let isStateChanged = false;
    CookiesArr.forEach(name => {
      if (nextProps[name] !== prevState[name]) {
        isStateChanged = true;
        newState[name] = nextProps[name];
      }
    });
    if (!isStateChanged) {
      return prevState;
    }
    return newState;
  }

  componentWillUnmount() {
    this.props.dispatch(clearCookiesSettings());
  }

  handleOnClickCheckBox = e => {
    try {
      const { cookiesSettings, dispatch } = this.props;
      const newState = cookiesSettings;
      cookiesSettings[e.target.name] = e.target.checked;
      dispatch(saveCookiesSettings(newState));
    } catch (e) {
      console.error(`Failed at handleOnClickCheckBox. Error: ${e.message}`);
    }
  };

  handleOnSaveAndClose = () => {
    try {
      const { cookiesSettings, onClickHideModal, onCloseTap } = this.props;

      Object.keys(CookieTypes).forEach(key => {
        const cookieFormName = `${CookieTypes[key].toString().toLowerCase()}Cookies`;
        const cookie = getCookie(CookieTypes[key]);
        const cookieState = this.state[cookieFormName];
        const cookieStoreState = cookiesSettings[cookieFormName];
        if (cookie === undefined && cookieState === true && cookieStoreState === null) {
          setCookie(CookieTypes[key], true);
        } else {
          setCookie(CookieTypes[key], cookieStoreState !== null ? cookieStoreState : false);
        }
      });

      onClickHideModal();
      onCloseTap();
      if (!cookiesSettings.analyticalCookies) {
        localStorage.clear("utmReferrer");
        localStorage.removeItem("utmParams");
        window.location.reload();
      }
    } catch (e) {
      console.error(`Failed at handleOnSaveAndClose. Error: ${e.message}`);
    }
  };

  render() {
    return (
      <div>
        <form className="form">
          <CookieModal
            secondaryButtonTitle="Save"
            secondaryButtonClassName="btn btn--primary btn--md btn--full"
            handleOnClickSecondaryButton={this.handleOnSaveAndClose}
          >
            <div className="cookie-modal cookie-preferences">
              <h3>Cookie Preferences (Controlled Consent)</h3>
              <p>Please select which types of cookies you want to be stored on your device.</p>

              <ul className="cookieList">
                <li>
                  <span className="tooltip_ui">
                    <span className="tooltip_ui-trigger">
                      <Field name="functionalCookies" label="Functional cookies" checked={true} disabled={true} />
                    </span>
                  </span>
                  These cookies are essential for the running of our website. Without these cookies our websites would not function properly. These are saved
                  temporarily as login information and expire once the browser is closed.
                </li>
                <li>
                  <Field name="analyticalCookies" label="Analytical cookies" checked={this.state.analyticalCookies} onChange={this.handleOnClickCheckBox} />
                  The information provided by analytical cookies allows us to analyse patterns of visitor behaviour and we use that information to enhance the
                  overall experience or identify areas of the website which may require maintenance. The information is anonymous (i.e. it cannot be used to
                  identify you and does not contain personal information such as your name and email address) and it is only used for statistical purposes.
                  Behavioral cookies are similar to analytical and remember that you have visited a website and use that information to provide you with content
                  which is tailored to your interests.
                </li>
                <li>
                  <Field
                    name="promotionalCookies"
                    label="Marketing/Promotional cookies"
                    checked={this.state.promotionalCookies}
                    onChange={this.handleOnClickCheckBox}
                  />
                  These cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual
                  user and thereby more valuable for publishers and third party advertisers.
                </li>
                <li>
                  <Field name="preferencesCookies" label="Preferences cookies" checked={this.state.preferencesCookies} onChange={this.handleOnClickCheckBox} />
                  Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or
                  the region that you are in.
                </li>
              </ul>
            </div>
          </CookieModal>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({ cookiesSettings: state.cookiesSettings });

export default connect(mapStateToProps)(CookieConsentForm);
