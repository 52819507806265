import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import addsTitleAndDescriptionMetaTagToHead from "./addsTitleAndDescriptionMetaTagToHead";
import Page from "./Page";
import { getSafe } from "./genericFunctions";
import $ from "jquery";
import fetch from "isomorphic-fetch";
import withCookies from "./withCookies";

const defaultDomain = "http://localhost:8082";
const defaultAPI = "http://localhost:8084";
const UTM_LP_KEY = "utm_lp=";
const chatEnabled = getSafe(() => process.env.REACT_APP_CHAT_ENABLED, false);

const addChat = () => {
  const s = document.createElement("script");
  s.setAttribute("type", "text/javascript");
  s.setAttribute("async", "async");
  s.setAttribute("defer", "defer");
  s.setAttribute("id", "hs-script-loader");
  s.setAttribute("src", "//js.hs-scripts.com/4178152.js");
  document.body.appendChild(s);
};

const getDomain = () => {
  let domain = getSafe(() => process.env.REACT_APP_LENDWISE_DOMAIN, defaultDomain);
  if (!domain || $.trim(domain.toString()) === "") {
    domain = defaultDomain;
  }
  return domain.toString();
};

const getAPI = () => {
  let api = getSafe(() => process.env.REACT_APP_LENDWISE_API, defaultAPI);
  if (!api || $.trim(api.toString()) === "") {
    api = defaultAPI;
  }
  return api.toString();
};

const getPathnameType = () => {
  const pathName = document.location.pathname;
  return pathName.split("/")[1].length > 0 ? pathName.split("/")[1] : null;
};

const getUtmParams = () => {
  return document.location.search.replace("?", "");
};

const getButton = (p, utmParams) => ({
  text: p.buttonText,
  link: `${getDomain()}${p.buttonLink}${p.buttonLink.includes("?") ? "&" : "?"}${UTM_LP_KEY}${getPathnameType()}${utmParams.length > 0 ? "&" + utmParams : ""}`
});

const DefaultLayout = ({ component: Component, targetDomain, path, page, button, ...rest }) => (
  <Route
    {...rest}
    render={matchProps => (
      <div className="default-layout">
        <Component {...matchProps} targetDomain={targetDomain} path={path} page={page} button={button} />
      </div>
    )}
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageConfig: null
    };
  }

  componentDidMount() {
    try {
      const typeLocPart = getPathnameType();
      if (!typeLocPart) {
        return;
      }

      if (chatEnabled) {
        setTimeout(() => addChat(), 3000);
      }

      const url = `${getAPI()}/v1/landing-pages/${typeLocPart}`;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(res => {
          if (!res.ok) {
            return res.json().then(error => {
              throw error;
            });
          }
          return res.json();
        })
        .then(json => this.setState({ ...this.state, pageConfig: json.data }))
        .catch(err => console.error(err));
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    const pageConfig = getSafe(() => this.state.pageConfig);
    if (!pageConfig) {
      return null;
    }

    const utmParams = getUtmParams();
    const layouts = pageConfig.paths.map((p, index) => (
      <DefaultLayout
        key={`layout-${index}`}
        path={`/${pageConfig.type}/${p.path}`}
        component={withCookies(addsTitleAndDescriptionMetaTagToHead(Page, { title: pageConfig.pageTitle, description: pageConfig.metaDescription }))}
        page={pageConfig}
        button={getButton(p, utmParams)}
        targetDomain={getDomain()}
      />
    ));

    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <Redirect to="/professional-qualification/get-a-quote" />
            </Route>
            {layouts}
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
