import React from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

class RangeSliderWithInputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.minRange
    };
    this.onSlide = this.onSlide.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  
  onSlide = (render, handle, value) => {
    this.setState({
      textValue: value[0].toFixed(0)
    });
    this.props.handleSliderValue({ [this.props.name]: value[0].toFixed(0) });
  };

  onChange = (render, handle, value) => {
    this.setState({
      value: value[0].toFixed(0)
    });
    this.props.handleSliderValue({ [this.props.name]: value[0].toFixed(0) });
  };

  handleChange(event) {
    if (event.target.value <= this.props.maxRange[0]) {
      this.setState({ value: event.target.value });
      this.props.handleSliderValue({ [event.target.name]: event.target.value });
    }
  }

  render() {
    const { value } = this.state;
    const { label, minRange, maxRange, name, flexboxInput = "", flexboxLabel = "", padding = "" } = this.props;
    return (
      <div className={`flexbox-calculator ${padding}`}>
        <div className="flexbox-container">
          <aside className={flexboxLabel}>
            <span className="calculator_subtitles">{label}</span>
          </aside>
          <aside className={flexboxInput}>
            <input type="text" name={name} className="form__field" value={this.state.value} onChange={this.handleChange} placeholder="I want to borrow" />
          </aside>
        </div>
        <div className="slider slider--filter">
          <Nouislider
            connect={[true, false]}
            start={value}
            behaviour="snap"
            range={{
              min: minRange,
              max: maxRange
            }}
            onSlide={this.onSlide}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}

export default RangeSliderWithInputField;
