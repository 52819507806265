import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import GoogleTagManager from "@redux-beacon/google-tag-manager";
import { createMiddleware } from "redux-beacon";
import { connectRouter } from "connected-react-router";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

// import the root reducer
import rootReducer from "../reducers/index";
import eventsMap from "../analytics";
import { getSafe } from "../genericFunctions";
import browserHistory from "../browserHistory";

const gtm = GoogleTagManager();
const gaMiddleware = createMiddleware(eventsMap, gtm);

// create an object for the default data
const defaultState = {};

// configuring reducers' persistance
// timeout is null to remove some warnings and issues caused
// by the package's in built timer
// blacklisting routing as we don't want routes to be persisted
// if they are not persisted then the user cannot change location on demand
const persistConfig = {
  key: "root",
  storage,
  timeout: null,
  blacklist: ["router"]
};

const persistedReducer = persistReducer(persistConfig, connectRouter(browserHistory)(rootReducer));

let configureStore = undefined;

const middlewares = [gaMiddleware, thunkMiddleware];

const environment = getSafe(() => process.env.NODE_ENV);
switch (environment) {
  case "production":
    configureStore = createStore(persistedReducer, defaultState, compose(applyMiddleware(...middlewares)));
    break;
  default:
    const createLogger = require("redux-logger").createLogger;
    const loggerMiddleware = createLogger();

    middlewares.push(loggerMiddleware);

    configureStore = createStore(
      persistedReducer,
      defaultState,
      compose(
        applyMiddleware(...middlewares),
        window.devToolsExtension ? window.devToolsExtension() : f => f
      )
    );

    if (module.hot) {
      module.hot.accept("../reducers/", () => {
        const nextRootReducer = require("../reducers/index").default;
        configureStore.replaceReducer(nextRootReducer);
      });
    }
    break;
}

const persistor = persistStore(configureStore);

export { persistor };

export default configureStore;
