import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import cookies from "./cookies";
import cookiesSettings from "./cookiesSettings";

const appReducer = combineReducers({ routing: routerReducer, cookies, cookiesSettings });

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
