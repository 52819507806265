/*eslint-disable no-unused-vars*/
import React from "react";
/*eslint-enable no-unused-vars*/
import YouTube from "react-youtube";

const ModalDialog = props => {
  const { onClick, visible } = props;
  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      rel: 0
    }
  };
  return (
    <span>
      <div className={`modal fade ${visible ? "show" : ""} `} role="dialog" style={{ display: visible ? "block" : "none" }}>
        <div className="modal-dialog-video modal-fullScreen">
          <button onClick={onClick} className="btn btn--alt">
            <svg role="img" className="icon icon-close">
              <use xlinkHref="/icons/icons.svg#icon-close" />
            </svg>
          </button>
          {visible && <YouTube className="videoIframe" videoId="NdWybcx_T-8" opts={opts} onEnd={onClick} />}
        </div>
      </div>
    </span>
  );
};

export default ModalDialog;
