import { sendRequest, receiveRequest, resolveJSON } from "./root";
import { COOKIES_URI } from "../actionUris";
import { RECEIVE_COOKIES_DATA, CLEAR_COOKIES_DATA, RECEIVE_COOKIES_SETTINGS, CLEAR_COOKIES_SETTINGS } from "../actionTypes";
import { toQueryString } from "../genericFunctions";

export const saveCookiesSettings = payload => ({
  type: RECEIVE_COOKIES_SETTINGS,
  payload
});

export const clearCookiesSettings = () => ({ type: CLEAR_COOKIES_SETTINGS });

export const receiveCookies = ({ payload }) => ({
  type: RECEIVE_COOKIES_DATA,
  payload
});

export const clearCookies = () => ({ type: CLEAR_COOKIES_DATA });

export const fetchCookies = filter => {
  const url = COOKIES_URI + toQueryString(filter);

  return dispatch => {
    dispatch(sendRequest());
    return fetch(url, {
      method: "GET"
      // headers: httpHeadersWithJWT(sessionStorage.getItem("jwt"))
    })
      .then(data => {
        dispatch(receiveRequest());
        return resolveJSON(data, dispatch, receiveCookies);
      })
      .catch(err => Promise.reject(err));
  };
};
