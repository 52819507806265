/*eslint-disable no-unused-vars*/
import React from "react";
/*eslint-enable no-unused-vars*/

export const CookieModal = props => (
  <div className={`modal modal--md fade in cookie-modal ${props.className ? props.className : ""}`} id="modal-alert" style={{ display: "block" }}>
    <div className="modal-dialog">
      <div className="modal-content cookie-modal-content">
        {props.handleOnClickClose && (
          <div classNam="modal-header">
            <button type="button" classNam="close" data-dismiss="modal" onClick={props.handleOnClickClose}>
              &times;
            </button>
          </div>
        )}
        <div className="modal-body cookie-modal-body">{props.children}</div>
        <div className={`modal-footer cookie-modal-footer${props.cookieModalClass ? ` ${props.cookieModalClass}` : ""}`}>
          <div className="row cookie-modal-footer-row">
            {props.handleOnClickSecondaryButton && (
              <div className={props.handleOnClickPrimaryButton ? "col-md-6 cols-xs-12" : "col-md-12 cols-xs-12"}>
                <button
                  type="button"
                  className={props.secondaryButtonClassName ? props.secondaryButtonClassName : "btn btn--ghost btn--md btn--grey btn--full"}
                  onClick={props.handleOnClickSecondaryButton}
                  data-dismiss="modal"
                >
                  {props.secondaryButtonTitle || "Cancel"}
                </button>
              </div>
            )}
            {props.handleOnClickPrimaryButton && (
              <div className={props.handleOnClickSecondaryButton ? "col-md-6 cols-xs-12" : "col-md-12 cols-xs-12"}>
                <button
                  type="button"
                  className={props.primaryButtonClassName ? props.primaryButtonClassName : "btn btn--primary btn--md btn--full"}
                  onClick={props.handleOnClickPrimaryButton}
                  data-dismiss="modal"
                >
                  {props.primaryButtonTitle || "Confirm"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);
