import React from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

class RangeSliderWithLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.minRange / 12
    };
    this.onSlide = this.onSlide.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onSlide = (render, handle, value) => {
    this.setState({
      value: value[0]
    });
    this.props.handleSliderValue({ [this.props.name]: value[0] * 12 });
  };

  onChange = (render, handle, value) => {
    this.setState({
      value: value[0]
    });
    this.props.handleSliderValue({ [this.props.name]: value[0] * 12 });
  };

  render() {
    const { value } = this.state;
    const { label, minRange, maxRange } = this.props;

    let yearValue = value;
    switch (Number.isInteger(value)) {
      case true: {
        yearValue = `${value} years`;
        break;
      }
      case false: {
        yearValue = `${Math.floor(value)} ½ years`;
        break;
      }
      default: {
        break;
      }
    }

    const minRangeValue = minRange / 12;
    const maxRangeValue = maxRange / 12;
    return (
      <div className="flexbox-calculator">
        <div className="flexbox-container">
          <aside className="flexbox-label">
            <span className="calculator_subtitles">{label}</span>
            <span className="calculator_subtitles">&nbsp; {yearValue}</span>
          </aside>
        </div>

        <div className="slider slider--filter">
          <Nouislider
            id="1"
            connect={[true, false]}
            start={value}
            step={0.5}
            behaviour="snap"
            range={{
              min: minRangeValue,
              max: maxRangeValue
            }}
            onSlide={this.onSlide}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}

export default RangeSliderWithLabel;
