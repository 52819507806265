import { LOCATION_CHANGE } from "react-router-redux";

const pageview = values => ({
  ...values,
  event: "pageview"
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Match the event definition to a Redux action:
const eventsMap = {
  [LOCATION_CHANGE]: action =>
    sleep(0).then(() =>
      pageview({
        ...action,
        page: {
          path: action.payload.pathname
        }
      })
    )
};

export default eventsMap;
