import moment from "moment";

export const displayPercentage = (num, minimumFractionDigits = 2, defaultText = "--") => {
  if (num === undefined || num === null) {
    return defaultText;
  }

  const v = num ? num.toLocaleString("en-GB", { style: "percent", minimumFractionDigits }) : "0.00%";
  console.error(num.toLocaleString("en-GB", { style: "percent", minimumFractionDigits }));
  if (num < 1 && v === "100.00%") {
    return "99.99%";
  }

  return v;
};

export const roundTo = (n, digitsArg) => {  
  const digits = !digitsArg? 0 : digitsArg;
  const multiplicator = Math.pow(10, digits);
  const number = parseFloat(n * multiplicator);
  const test = Math.round(number) / multiplicator;
  return +test;
};

export const addMonth = (currentDate, month) => {
  return moment(currentDate).add(month, "M");
};
