/*eslint-disable no-unused-vars*/
import React from "react";
/*eslint-enable no-unused-vars*/
import { getSafe } from "./genericFunctions";

const PlayVideo = props => {
  const { onClick, calculator, button } = props;
  let { showVideo, showCalculator } = props;
  const videoEnabled = getSafe(() => process.env.REACT_APP_VIDEO_ENABLED, true) || true;
  if (showVideo === undefined) {
    showVideo = true;
  }
  if (showCalculator === undefined) {
    showCalculator = true;
  }
  if (!videoEnabled || (!showCalculator && !showVideo)) {
    return null;
  }

  return (
    <span className="btn-section">
      {showCalculator && (
        <span className="btn-overlay">
          <p className="margin-bottom-10">
            <a className="header-info-text" href="tel:+442038907270">
              <span>
                <img className="icon_phone_info" src="https://app.lendwise.com/icons/icon-phone.svg" alt="Lendwise" />
              </span>
              <span className="header_info-hide-sm">+44 (0) 20 3890 7270</span>
            </a>
          </p>
          {calculator}
          <br />
          <a href={button.link} className="btn btn--arrow btn--alt">
            <span>{props.button.text}</span>
            <svg role="img" className="icon icon-arrow-right">
              <use xlinkHref="/icons/icons.svg#icon-arrow-right" />
            </svg>
          </a>
        </span>
      )}
      {showVideo && <br />}
      {showVideo && (
        <span className="btn-overlay">
          <button className="icon-btn" onClick={onClick}>
            <img className="play-btn" src="/icons/play-arrow.svg" alt="Click play to see how Lendwise works" />
          </button>
          <p className="paragrapSize">Click play to see how Lendwise works</p>
        </span>
      )}
    </span>
  );
};

export default PlayVideo;
