import React, { Component } from "react";
import { connect } from "react-redux";
import { getCookie, setCookie, isCookiesEnabled, getSafe } from "./genericFunctions";
import { LOCATION_CHANGE } from "react-router-redux";
import "./css/bootstrap.css";
import "./css/styles.css";
import "./css/custom.css";
import "./css/cookies-alert.css";
import "./css/cookies-preferences.css";
import "./css/cookies-fadedrop.css";
import "./css/cookies-cookies-settings-button.css";
import "./css/cookies-accept-all-cookies-button.css";
import "./css/cookies-save-button.css";
import Modernizr from "./js/lib/modernizr-custom";
import svg4everybody from "svg4everybody";
import $ from "jquery";
import Footer from "./Footer";
import Menu from "./Menu";
import Header from "./Header";
import ModalDialog from "./Modal";
import PlayVideo from "./PlayVideo";
import Calculator from "./components/Calculator";
import CookieAlert from "./components/CookieAlert";

const createMarkup = __html => ({ __html });

const section0Quote =
  "“I would definitely encourage anyone who wants to continue with higher education but are worried for funds to contact this company because they will be able to help.”";
const section0QuoteAuthor = "— Emmanuel, MSc Quantitative Financial Economics Student – Trustpilot.com";
const section1Quote = "“Lendwise were invaluable to me and my studies.”";
const section1QuoteAuthor = "— Aaron, Kings College London student – Trustpilot.com";
const section2Quote =
  "“Excellent - I have had a fantastic experience with Lendwise. The level of customer care was exceptional, everything was explained clearly and I felt very comfortable asking any questions.”";
const section2QuoteAuthor = "— Cerise, Royal College of Art student – Trustpilot.com";

const isIE11orBelow = () => false || !!document.documentMode;

const initialState = {
  showCookieAlert: getCookie("cookie-alert-closed") !== "true",
  webPSupport: false,
  loading: false,
  visible: false
};

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  getMain(page, button, webPSupport) {
    return isIE11orBelow() ? (
      <div className="main">{this.getMainBody(page, button, false)}</div>
    ) : (
      <main className="main">{this.getMainBody(page, button, webPSupport)}</main>
    );
  }

  getMainBody(page, button, webPSupport) {
    const header0 = getSafe(() => page.sections[0].header);
    const subHeader0 = getSafe(() => page.sections[0].subHeader);
    const header1 = getSafe(() => page.sections[1].header);
    const subHeader1 = getSafe(() => page.sections[1].subHeader);
    const header2 = getSafe(() => page.sections[2].header);
    const subHeader2 = getSafe(() => page.sections[2].subHeader);
    const calculator = (
      <div className="calculator">
        <Calculator pageItems={page} />
      </div>
    );
    const section0Items = getSafe(() =>
      page.sections[0].items.map((e, i) => (
        <li key={`section0-item-${i}`}>
          <div dangerouslySetInnerHTML={createMarkup(e.item)} />
        </li>
      ))
    );
    const section1Items = getSafe(() =>
      page.sections[1].items.map((e, i) => (
        <li key={`section1-item-${i}`}>
          <div dangerouslySetInnerHTML={createMarkup(e.item)} />
        </li>
      ))
    );
    const section2Items = getSafe(() =>
      page.sections[2].items.map((e, i) => (
        <li key={`section2-item-${i}`}>
          <div dangerouslySetInnerHTML={createMarkup(e.item)} />
        </li>
      ))
    );
    const section0ButtonFooterTextLine1 = getSafe(() => page.sections[0].buttonFooterTextLine1);
    const section0ButtonFooterTextLine2 = getSafe(() => page.sections[0].buttonFooterTextLine2);
    const section1ButtonFooterTextLine1 = getSafe(() => page.sections[1].buttonFooterTextLine1);
    const section1ButtonFooterTextLine2 = getSafe(() => page.sections[1].buttonFooterTextLine2);
    const section2ButtonFooterTextLine1 = getSafe(() => page.sections[2].buttonFooterTextLine1);
    const section2ButtonFooterTextLine2 = getSafe(() => page.sections[2].buttonFooterTextLine2);
    const justifyContentStart = "flex-box-start";

    const showVideo = getSafe(() => page.showVideo, false);
    const showCalculator = getSafe(() => page.showCalculator, false);
    const image1 = getSafe(() => page.type) === "2fly" ? "/images/PilotLP1.jpg" : "/images/cover-what.jpg";
    const image1Web = getSafe(() => page.type) === "2fly" ? "/images/PilotLP1.webp" : "/images/cover-what.webp";
    const image2 = getSafe(() => page.type) === "2fly" ? "/images/PilotLP2.jpg" : "/images/cover-investors.jpg";
    const image2Web = getSafe(() => page.type) === "2fly" ? "/images/PilotLP2.webp" : "/images/cover-investors.webp";
    const image3 = getSafe(() => page.type) === "2fly" ? "/images/PilotLP3.jpg" : "/images/cover-learners.jpg";
    const image3Web = getSafe(() => page.type) === "2fly" ? "/images/PilotLP3.webp" : "/images/cover-learners.webp";
    if (webPSupport) {
      return (
        <div>
          {this.getSection(
            " product",
            "product",
            "",
            image1Web,
            section0Quote,
            section0QuoteAuthor,
            header0,
            true,
            subHeader0,
            section0Items,
            button,
            section0ButtonFooterTextLine1,
            section0ButtonFooterTextLine2,
            calculator,
            justifyContentStart,
            showVideo,
            showCalculator
          )}
          {this.getSection(
            " cover--alt example",
            "example",
            " cover__img--zoom",
            image2Web,
            section1Quote,
            section1QuoteAuthor,
            header1,
            false,
            subHeader1,
            section1Items,
            button,
            section1ButtonFooterTextLine1,
            section1ButtonFooterTextLine2
          )}
          {section2Items &&
            this.getSection(
              " more-info",
              "more-info",
              "",
              image3Web,
              section2Quote,
              section2QuoteAuthor,
              header2,
              false,
              subHeader2,
              section2Items,
              button,
              section2ButtonFooterTextLine1,
              section2ButtonFooterTextLine2
            )}
        </div>
      );
    }

    return (
      <div>
        {this.getSection(
          " product",
          "product",
          "",
          image1,
          section0Quote,
          section0QuoteAuthor,
          header0,
          true,
          subHeader0,
          section0Items,
          button,
          section0ButtonFooterTextLine1,
          section0ButtonFooterTextLine2,
          calculator,
          justifyContentStart
        )}
        {this.getSection(
          " cover--alt example",
          "example",
          " cover__img--zoom",
          image2,
          section1Quote,
          section1QuoteAuthor,
          header1,
          false,
          subHeader1,
          section1Items,
          button,
          section1ButtonFooterTextLine1,
          section1ButtonFooterTextLine2
        )}
        {section2Items &&
          this.getSection(
            " more-info",
            "more-info",
            "",
            image3,
            section2Quote,
            section2QuoteAuthor,
            header2,
            false,
            subHeader2,
            section2Items,
            button,
            section2ButtonFooterTextLine1,
            section2ButtonFooterTextLine2
          )}
      </div>
    );
  }

  getSection(
    sectionClass,
    id,
    divClass,
    backgroundImage,
    pText,
    iText,
    header,
    showOnlyMobile,
    subHeader,
    sectionItems,
    button,
    line1,
    line2,
    calculator,
    justifyContentStart,
    showVideo,
    showCalculator
  ) {
    return isIE11orBelow() ? (
      <div className={`scrollable-section cover${sectionClass}`} id={id}>
        {this.getSectionPart(id, divClass, backgroundImage, pText, iText, calculator, button, showVideo, showCalculator)}
        {this.getCoverContent(id, header, showOnlyMobile, subHeader, sectionItems, button, line1, line2, justifyContentStart)}
      </div>
    ) : (
      <section className={`scrollable-section cover${sectionClass}`} id={id}>
        {this.getSectionPart(id, divClass, backgroundImage, pText, iText, calculator, button, showVideo, showCalculator)}
        {this.getCoverContent(id, header, showOnlyMobile, subHeader, sectionItems, button, line1, line2, justifyContentStart)}
      </section>
    );
  }

  getSectionPart(id, divClass, backgroundImage, pText, iText, calculator, button, showVideo, showCalculator) {
    return (
      <div className={`cover__img${divClass}`} style={{ backgroundImage: `url('${backgroundImage}')` }}>
        {id === "product" && (
          <PlayVideo
            onClick={this.handleOnClickIcon}
            calculator={calculator}
            button={button}
            showVideo={showVideo}
            showCalculator={showCalculator}
          />
        )}

        <div className="cover__quote">
          <p>
            {pText}
            <i>{iText}</i>
          </p>
        </div>
      </div>
    );
  }

  getCoverContent(id, header, showOnlyMobile, subHeader, sectionItems, button, line1, line2, justifyContentStart) {
    const coverContentCssClass = justifyContentStart === undefined ? "cover__content" : "cover__content " + justifyContentStart;
    return (
      <div className={coverContentCssClass}>
        <div className="cover__text">
          {header && <h1 className="cover__title">{header}</h1>}
          {showOnlyMobile && (
            <a id={`mobile-scroll-${id}`} href={button.link} className="btn btn--arrow btn--alt showOnMobile">
              <span>{button.text}</span>
              <svg role="img" className="icon icon-arrow-right">
                <use xlinkHref="/icons/icons.svg#icon-arrow-right" />
              </svg>
            </a>
          )}
          {showOnlyMobile && <p className="showOnlyMobile" />}
          {subHeader && <p>{subHeader}</p>}
          <ul className="list-linked">{sectionItems}</ul>
          <a href={button.link} className="btn btn--arrow btn--alt">
            <span>{button.text}</span>
            <svg role="img" className="icon icon-arrow-right">
              <use xlinkHref="/icons/icons.svg#icon-arrow-right" />
            </svg>
          </a>
          {this.getSectionButtonFooter(line1, line2)}
        </div>
      </div>
    );
  }

  getSectionButtonFooter(line1, line2) {
    if (line1 && !line2) {
      return (
        <div className="buttonFooterStyle">
          {line1.indexOf("*") !== -1 && <span className="blue-asterisk">* </span>}
          {line1.indexOf("*") !== -1 ? <span>{line1.trim().replace("*", "")}</span> : <span>{line1}</span>}
        </div>
      );
    }
    if (line1 && line2) {
      return (
        <div className="buttonFooterStyle">
          {line1.indexOf("*") !== -1 && <span className="blue-asterisk">* </span>}
          {line1.indexOf("*") !== -1 ? <span>{line1.trim().replace("*", "")}</span> : <span>{line1}</span>}
          <div style={{ padding: "3px" }} />
          {line2.indexOf("*") !== -1 && <span className="blue-asterisk">* </span>}
          {line2.indexOf("*") !== -1 ? <span>{line2.trim().replace("*", "")}</span> : <span>{line2}</span>}
        </div>
      );
    }
    return null;
  }

  handleOnClickIcon = () => {
    this.setState({ ...this.state, visible: true });
  };

  handleOnCloseClick = () => {
    this.setState({ ...this.state, visible: false });
    };

  UNSAFE_componentWillMount() {
    this.setState({ ...this.state, loading: true });
    Modernizr.on("webp", result => {
      if (result) {
        // the browser has webp support
        this.setState({ ...this.state, webPSupport: true, loading: false });
        return;
      }
      this.setState({ ...this.state, loading: false });
    });
  }

  componentDidMount = () => {
    const { dispatch, location } = this.props;
    dispatch({ type: LOCATION_CHANGE, payload: { ...location } });

    $(document).ready(function() {
      //SVG
      svg4everybody();

      //MENU
      $(document).on("click", ".menu-trigger, .menu__close", function() {
        $("body").toggleClass("menu-on");
      });
      $(document).on("click", ".menu a", function() {
        $("body").removeClass("menu-on");
      });

      //SCROLL TO SECTION
      $(document).on("click", "*", function() {
        var arr = $(this)
          .find("a")
          .get();
        for (var i = 0; i < arr.length; i++) {
          if ($(arr[i]).is(":focus") && $(arr[i]).data("scroll")) {
            document.getElementById($(arr[i]).data("scroll")).scrollIntoView();
            break;
          }
        }
      });
    });
  };

  handleOnCloseTapCookieAlert = () => {
    this.setState({ ...this.state, showCookieAlert: false });
    setCookie("cookie-alert-closed", true);
  };

  handleOnCloseTapCookieAlertStateOnly = () => {
    this.setState({ ...this.state, showCookieAlert: false });
  };

  render = () => {
    const { page, button } = this.props;

    if (this.state.loading) {
      return null;
    }

    return (
      <div className="page page--home">
        <Header page={page} button={button} />
        <Menu page={page} button={button} />
        <div className="menu-overlay" />
        {this.getMain(page, button, this.state.webPSupport)}
        <Footer />
        <ModalDialog visible={this.state.visible} onClick={this.handleOnCloseClick} />
        {this.state.showCookieAlert && isCookiesEnabled() && (
          <CookieAlert {...this.props} onCloseTap={this.handleOnCloseTapCookieAlert} onCloseTapStateOnly={this.handleOnCloseTapCookieAlertStateOnly} />
        )}
      </div>
    );
  };
}

export default connect()(Page);
