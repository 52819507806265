/*eslint-disable no-unused-vars*/
import React from "react";
/*eslint-enable no-unused-vars*/

const Menu = props => {
  const { page, button } = props;
  return (
    <div className="menu">
      <button type="button" className="menu__close" aria-label="close">
        <svg role="img" className="icon icon-close">
          <use xlinkHref="/icons/icons.svg#icon-close" />
        </svg>
      </button>
      <ul className="menu__nav">
        <li>
          <a href="#!" data-scroll="product">
            {page.menuTitle}
          </a>
        </li>
        <li>
          <a href="#!" data-scroll="example">
            Example
          </a>
        </li>
        <li>
          <a href="#!" data-scroll="more-info">
            More Info
          </a>
        </li>
        <li>
          <a href="https://lendwise.com/help/">Help</a>
        </li>
      </ul>
      <a href={button.link} className="btn btn--arrow btn--alt">
        <span>{button.text}</span>
        <svg role="img" className="icon icon-arrow-right">
          <use xlinkHref="/icons/icons.svg#icon-arrow-right" />
        </svg>
      </a>
    </div>
  );
};

export default Menu;
