import React from "react";
import "nouislider/distribute/nouislider.css";
import Checkbox from "./Checkbox";
import RangeSliderWithLabel from "./RangeSliderWithLabel";
import { EvaluateWithGrace, CalculateApr } from "../financial/pmtFromApr";
import RangeSliderWithInputFieldFullWidth from "./RangeSliderWithInputFieldFullWidth";

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      loanAmountValue: this.props.pageItems.loanAmountMin,
      courseDurationMonths: 0,
      loanDurationValue: this.props.pageItems.loanDurationMonthsMin
    };
    this.handleBlur = this.handleBlur.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleCalculatorValue = value => {
    this.setState({ ...this.state, ...value });
  };

  handleToggle = e => {
    e.target.focus();
    this.setState({ ...this.state, show: !this.state.show });
  };
  handleBlur = e => {
    // firefox onBlur issue workaround
    if (e.nativeEvent.explicitOriginalTarget && e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget) {
      return;
    }
  };

  render() {
    let index = 1;
    const { pageItems } = this.props;
    return (
      <div className="calculator-box">
        <RangeSliderWithInputFieldFullWidth
          id={index}
          label="I want to borrow £"
          minRange={[pageItems.loanAmountMin]}
          maxRange={[pageItems.loanAmountMax]}
          step={1000}
          handleSliderValue={this.handleCalculatorValue}
          name="loanAmountValue"
          flexboxLabel="flexbox-label full-width"
        />
        <RangeSliderWithLabel
          id={index + 1}
          label="Over"
          minRange={[pageItems.loanDurationMonthsMin]}
          maxRange={[pageItems.loanDurationMonthsMax]}
          useDropdown={true}
          handleSliderValue={this.handleCalculatorValue}
          name="loanDurationValue"
        />
        <Checkbox
          name="courseDurationMonths"
          minRange={pageItems.courseDurationMonthsMin}
          maxRange={pageItems.courseDurationMonthsMax}
          handleSliderValue={this.handleCalculatorValue}
        />
        <span>
          <div className="flexbox-container flexbox-center">
            <aside>
              <span className="calculator_Title">
                {CalculateApr(
                  Number(pageItems.feePercentage),
                  Number(pageItems.feeAmountMin),
                  pageItems.interestRate,
                  Number(this.state.loanAmountValue),
                  Number(this.state.loanDurationValue),
                  Number(this.state.courseDurationMonths)
                )}
                %
              </span>
              <p className="italic">Representative APR*</p>
            </aside>
            <aside>
              <span className="calculator_Title">
                £
                {EvaluateWithGrace(
                  Number(pageItems.feePercentage),
                  Number(pageItems.feeAmountMin),
                  Number(this.state.loanAmountValue),
                  pageItems.interestRate,
                  Number(this.state.loanDurationValue),
                  Number(this.state.courseDurationMonths)
                )}
              </span>
              <p className="italic">Monthly repayment*</p>
            </aside>
          </div>
        </span>
      </div>
    );
  }
}

export default Calculator;
