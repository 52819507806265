import { RECEIVE_COOKIES_DATA, CLEAR_COOKIES_DATA } from "../actionTypes";
import { reducePayload } from "../genericFunctions";

const initialState = {
};

const cookies = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_COOKIES_DATA: {
      if (!action.payload || action.payload.length === 0) {
        return state;
      }
      return { ...state, ...reducePayload(action.payload) };
    }
    case CLEAR_COOKIES_DATA: {
      return initialState;
    }
    default:
      return state;
  }
};

export default cookies;
