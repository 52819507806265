import moment from "moment";
import xirr from "xirr";
import { addMonth } from "../functions/functions";
//Already tested
export const roundToDigits = (num, digits = 2) => +(Math.round(num + `e+${digits}`) + `e-${digits}`);

export const truncateTo = (val, digits) => {
  return val.toFixed(digits);
};

const monthsDecimal = 12;

const hundredDecimal = 100;

export const monthlyInterestRate = interestRate => {
  return interestRate / hundredDecimal / monthsDecimal;
};

export const Evaluate = (loanAmount, interestRate, instalments) => {
  if (interestRate === 0) {
    return roundToDigits(truncateTo(loanAmount / instalments, 10), 2);
  }

  if (instalments === 0) {
    return {
      error: "instalments must be greater than 0"
    };
  }

  // Monthly Loan Payment = Total Loan Amount (A) / Discount Factor (D)
  //    D = ((1+r)^n-1) / (r(1+r)^n) where:
  //        r = periodic interest rate (converted to decimal by dividing by 100 and then by 12 which is how many months in a year)
  //        n = installments
  const A = loanAmount;
  const n = instalments;
  const r = monthlyInterestRate(interestRate);

  // (1+r)^n - 1
  const DNumerator = (Math.pow((1 + r), n)) - 1;
  // r(1+r)^n
  const DDenominator = r * (Math.pow((1 + r), n));

  // D = ((1+r)^n-1) / (r(1+r)^n) 
  const D = DNumerator / DDenominator;
  const monthlyPayment = A / D;
  return roundToDigits(monthlyPayment, 2);
};

// EvaluateWithGrace returns the PMT value for a loan which has grace payments
export const EvaluateWithGrace = (feesPercentage, minFee, loanAmount, interestRate, instalments, grace) => {
  let fees = loanAmount * (feesPercentage / 100);
  if (fees < minFee) {
    fees = minFee;
  }
  const loanAmountWithFees = loanAmount + fees;
  if (grace === 0 || interestRate === 0) {
    return Evaluate(loanAmountWithFees, interestRate, instalments);
  }

  if (interestRate === 0) {
    return {
      error: "interestRate must be greater than 0"
    };
  }

  const r = monthlyInterestRate(interestRate);
  const monthlyAccruedInterest = roundToDigits(truncateTo(loanAmount * r, 10), 2);
  const accruedInterest = monthlyAccruedInterest * grace;
  const newLoanAmount = loanAmountWithFees + accruedInterest;
  return Evaluate(newLoanAmount, interestRate, instalments);
};

export const CalculateApr = (feesPercentage, minFee, interstRatePercentage, loanAmount, loanDuration, grace) => {
  const totalmonthlypayment = EvaluateWithGrace(feesPercentage, minFee, loanAmount, interstRatePercentage, loanDuration, grace); //pmt:360 , loanduration : 36 //grace 15  //36=15 = 51 15 //
  let fees = loanAmount * (feesPercentage / 100);
  if (fees < minFee) {
    fees = minFee;
  }
  const totalLoanAmount = loanAmount + fees;
  const values = [];
  const currentDate = new Date();

  values.push({ amount: -totalLoanAmount, when: moment(currentDate).toDate() });
  if (grace > 0) {
    for (let i = 1; i <= grace + loanDuration; i++) {
      if (i <= grace) {
        values.push({
          amount: 0,
          when: addMonth(currentDate, i)
        });
      } else {
        values.push({
          amount: totalmonthlypayment,
          when: addMonth(currentDate, i)
        });
      }
    }
  } else {
    for (let i = 1; i <= loanDuration; i++) {
      values.push({
        amount: totalmonthlypayment,
        when: addMonth(currentDate, i)
      });
    }
  }

  return (xirr(values).toFixed(4) * 100).toFixed(2);
};
