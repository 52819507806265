/*eslint-disable no-unused-vars*/
import React from "react";
/*eslint-enable no-unused-vars*/
import moment from "moment";

const Footer = () => (
  <div className="footer">
    <div className="container">
      <p style={{ padding: "3px", marginTop: "0px", fontSize: "10px", fontStyle: "italic" }}>
        * Representative rate and repayment amounts shown are indicative and based on an example.  Your interest rate and repayments will be based on your profile and may differ.
        Above representative example assumes disbursements occur at the start of the loan and repayments start 1 month after the end of your course for full-time courses and, for
        part-time courses, the example assumes repayments begin the month after the course begins.
      </p>
      <div className="d-flex justify-content-between">
        <div className="">
          <p>
            Lendwise Ltd is authorised and regulated by the Financial Conduct Authority under firm registration number{" "}
            <a className="footerLink" href="https://register.fca.org.uk/s/firm?id=001b000003rrNRnAAM">
              782496
            </a>
            . Lendwise Ltd is not covered by the Financial Services Compensation Scheme. Registered in England (Co. No.{" "}
            <a className="footerLink" href="https://beta.companieshouse.gov.uk/company/10466048">
              10466048
            </a>
            ) with registered office at{" "}
            <a className="footerLink" href="https://maps.google.com/?q=3+More+London+Riverside,+London,+United+Kingdom,+SE1+2AQ&entry=gmail&source=g">
              3 More London Riverside, London, United Kingdom, SE1 2AQ
            </a>
            . Registered with the Office of the Information Commissioner (No.{" "}
            <a className="footerLink" href="https://ico.org.uk/ESDWebPages/Entry/ZA281795">
              ZA281795
            </a>
            ).
          </p>
          <p>
            <a className="footerLink" href="https://lendwise.com/legal/">
              Legal Terms and Policies
            </a>
          </p>
          <p className="footer__copy">
            <span>Copyright &copy; Lendwise {moment().format("YYYY")}. All rights reserved</span>
          </p>
        </div>

        <div className="footer__brand">
          <img src="/images/logo-lendwise--white.svg" alt="" className="footer__logo" />
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
