/*eslint-disable no-unused-vars*/
import React, { Component } from "react";
/*eslint-enable no-unused-vars*/
import { connect } from "react-redux";
import { getCookie, setCookie, isCookieEnabled } from "./genericFunctions";

const CookieTypesFunctional = "FUNCTIONAL";

export default function withCookies(WrappedComponent) {
  class ComponentWithCookies extends Component {
    componentDidMount() {
      try {
        // by default all cookie types must be disabled unless specified otherwise by the user, except functional
        if (!getCookie(CookieTypesFunctional)) {
          setCookie(CookieTypesFunctional, true, 0);
        }

        if (!isCookieEnabled()) {
          alert(
            "It looks like you have disabled all cookies. Some cookies are necessary for our website to function properly. Please enable accordingly. Thank you for your understanding."
          );
        }
      } catch (e) {
        console.error(`Failed at componentDidMount. Error: ${e.message}`);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect()(ComponentWithCookies);
}
