import React from "react";
import "nouislider/distribute/nouislider.css";
import RangeSliderWithInputField from "./RangeSliderWithInputField";

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      vissible: false
    };
    this._handleChange = this._handleChange.bind(this);
  }
  _handleChange = () => {
    this.setState({
      checked: !this.state.checked,
      vissible: !this.state.vissible
    });
    if (!this.state.checked === false) {
      this.props.handleSliderValue({ [this.props.name]: 0 });
    }
  };

  render() {
    const { checked, vissible } = this.state;
    const { disabled = false, minRange, maxRange } = this.props;
    return (
      <div className="flexbox-calculator">
        <div className="flexbox-container">
          <aside className="flex-checkbox">
            <div className="React__checkbox">
              <label className="checkboxLabel">
                <input type="checkbox" className="React__checkbox--input" checked={checked} disabled={disabled} onChange={this._handleChange} />
                <span className="React__checkbox--span" />
              </label>
            </div>
          </aside>
          <aside className="flex-checkbox-label">
            <div>
              <span className="calculator_subtitles">My course is full-time</span>
            </div>
          </aside>
        </div>
        {vissible && (
          <RangeSliderWithInputField
            id="courseSlider"
            label="Course Duration Months"
            minRange={[minRange]}
            maxRange={[maxRange]}
            name={this.props.name}
            handleSliderValue={this.props.handleSliderValue}
            flexboxInput="flexbox-input-checkbox"
            flexboxLabel="flexbox-label-checkbox"
            padding="padding-0px"
          />
        )}
      </div>
    );
  }
}

export default Checkbox;
