/*eslint-disable no-unused-vars*/
import React from "react";
/*eslint-enable no-unused-vars*/

const isIE11orBelow = () => false || !!document.documentMode;

const HeaderBody = ({ page, button }) => (
  <div className="container">
    <div className="logo-wrap">
      <img className="logo logo--color" src="/images/logo-lendwise.svg" alt="Lendwise" />
      <img className="logo logo--white" src="/images/logo-lendwise--white.svg" alt="Lendwise" />
    </div>
    <ul className="header__nav">
      <li className="header__item">
        <a href="#!" data-scroll="product">
          {page.menuTitle}
        </a>
      </li>
      <li className="header__item">
        <a href="#!" data-scroll="example">
          Example
        </a>
      </li>
      <li className="header__item">
        <a href="#!" data-scroll="more-info">
          More Info
        </a>
      </li>
      <li className="header__item">
        <a href="https://lendwise.com/help/">Help</a>
      </li>
      <li>
        <a href={button.link} className="btn btn--arrow btn--alt">
          <span>{button.text}</span>
          <svg role="img" className="icon icon-arrow-right">
            <use xlinkHref="/icons/icons.svg#icon-arrow-right" />
          </svg>
        </a>
      </li>
    </ul>
    <button type="button" className="menu-trigger" aria-label="menu">
      <svg role="img" className="icon icon-menu">
        <use xlinkHref="/icons/icons.svg#icon-menu" />
      </svg>
    </button>
  </div>
);

const Header = props => {
  const { page, button } = props;
  const header = <HeaderBody page={page} button={button} />;
  return isIE11orBelow() ? <div className="header">{header}</div> : <header className="header">{header}</header>;
};

export default Header;
