import { RECEIVE_COOKIES_SETTINGS, CLEAR_COOKIES_SETTINGS } from "../actionTypes";

const initialState = {
  functionalCookies: true,
  analyticalCookies: null,
  promotionalCookies: null,
  preferencesCookies: null
};

const cookiesSettings = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_COOKIES_SETTINGS: {
      if (!action.payload) {
        return state;
      }
      return { ...state, ...action.payload };
    }
    case CLEAR_COOKIES_SETTINGS: {
      return initialState;
    }
    default:
      return state;
  }
};

export default cookiesSettings;
