/*eslint-disable no-unused-vars*/
import React, { Component } from "react";
/*eslint-enable no-unused-vars*/

const defaultTitle="Peer to Peer Lending UK & Investing (P2P) Platform | Lendwise.com";
const defaultDescription="Borrow or Invest in Education Finance Loans in the UK. Fair interest rates and returns. FCA Regulated. Capital at Risk.";

const insertAfter = (newNode, referenceNode) => referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);

// This is a high order component to be used when we need to add/change the title, and the meta tag description on a page
export default function addsTitleAndDescriptionMetaTagToHead(WrappedComponent, { title = defaultTitle, description = defaultDescription }) {
  class HeadTitleAndDecriptionMetaTagComponent extends Component {
    componentDidMount() {
      const existingTitle = document.getElementsByTagName("title");
      if (existingTitle.length !== 0) {
        existingTitle[0].innerHTML = title;
      } else {
        const newTitle = document.createElement("title");
        newTitle.innerText = title;
        document.getElementsByTagName("head")[0].appendChild(newTitle);
      }
      const existingMetaTags = document.getElementsByTagName("meta");
      const descriptionMetaTag = [].slice.call(existingMetaTags).filter(e => e.getAttribute("name") === "description");
      if (descriptionMetaTag.length !== 0) {
        document.querySelector("meta[name='description']").setAttribute("content", description);
        return;
      }
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content = description;
      insertAfter(meta, document.getElementsByTagName("title")[0]);
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return HeadTitleAndDecriptionMetaTagComponent;
}