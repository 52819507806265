/*eslint-disable no-unused-vars*/
import React, { Component } from "react";
/*eslint-enable no-unused-vars*/
import CookieConsentForm from "./CookieConsentForm";
import { getCookie, getCookieWrapper, setCookie } from "../genericFunctions";
import { CookieTypes } from "../constants";
import { CookieModal } from "./Modals";

class CookieAlert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCookieAlertOpen: getCookie("cookie-alert-closed") !== "true",
      isConsentFormOpen: false,
      functionalCookies: true,
      analyticalCookies: false,
      promotionalCookies: false,
      preferencesCookies: false
    };
  }

  componentDidMount() {
    if (window.location.toString().indexOf("cac=true") !== -1) {
      this.setState({ ...this.state, isCookieAlertOpen: false });
    }
  }

  handleOnClickCookieSettings = () => {
    try {
      this.setState({
        ...this.state,
        isConsentFormOpen: true,
        ...getCookieWrapper("analyticalCookies", "Analytical"),
        ...getCookieWrapper("promotionalCookies", "Promotional"),
        ...getCookieWrapper("preferencesCookies", "Preferences")
      });
    } catch (e) {
      console.error(`Failed at handleOnClickCookieSettings. Error: ${e.message}`);
    }
  };

  handleOnClickHideCookieConsentModal = () => {
    try {
      this.setState({ ...this.state, isConsentFormOpen: false });
    } catch (e) {
      console.error(`Failed at handleOnClickHideCookieConsentModal. Error: ${e.message}`);
    }
  };

  handleOnAcceptAllCookies = () => {
    try {
      Object.keys(CookieTypes).forEach(key => {
        setCookie(CookieTypes[key], true);
      });

      this.handleOnClickHideCookieConsentModal();
      this.props.onCloseTap();
      window.location.reload();
    } catch (e) {
      console.error(`Failed at handleOnAcceptAllCookies. Error: ${e.message}`);
    }
  };

  handleLearnMoreAboutCookies = e => {
    try {
      e.preventDefault();
      window.location.href = `${this.props.targetDomain}/cookie-policy?cac=true`;
    } catch (e) {
      console.error(`Failed at handleOnAcceptAllCookies. Error: ${e.message}`);
    }
  };

  render() {
    return (
      <div>
        {this.state.isCookieAlertOpen && (
          <CookieModal
            className="cookie-alert"
            secondaryButtonTitle="Cookies Settings"
            secondaryButtonClassName="btn btn--ghost btn--grey btn--md btn--full"
            handleOnClickSecondaryButton={this.handleOnClickCookieSettings}
            primaryButtonTitle="Accept All Cookies"
            primaryButtonClassName="btn btn--alt btn--md btn--full"
            handleOnClickPrimaryButton={this.handleOnAcceptAllCookies}
            cookieModalClass="cookie-modal-footer-alert"
          >
            <div className="cookie-modal">
              <h3>Cookies</h3>
              <div className="container row-flex cookies-alert-container">
                <div className="row">
                  <div className="col-md" style={{ paddingLeft: "0" }}>
                    <p className="col-md header_alert--text">
                      We use cookies and similar technologies to operate and improve our site, measurement and analytics, as well as advertising.&nbsp;
                      <a style={{ textDecoration: "underline", color: "#4244cb" }} href="#!" onClick={this.handleLearnMoreAboutCookies}>
                        Learn more about cookies
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </CookieModal>
        )}
        {this.state.isConsentFormOpen && (
          <CookieConsentForm
            onCloseTap={this.props.onCloseTap}
            onClickHideModal={this.handleOnClickHideCookieConsentModal}
            functionalCookies={this.state.functionalCookies}
            analyticalCookies={this.state.analyticalCookies}
            promotionalCookies={this.state.promotionalCookies}
            preferencesCookies={this.state.preferencesCookies}
          />
        )}
        {(this.state.isConsentFormOpen || this.state.isCookieAlertOpen) && <div className="modal-backdrop fade in" />}
      </div>
    );
  }
}
export default CookieAlert;
