import { CookieTypes } from "./constants";

export const convertToArray = obj => (!obj || isEmptyObject(obj) ? [] : Object.keys(obj).map(elem => obj[elem]));

export const getSafe = (fn, defaultValue) => {
  try {
    return fn();
  } catch (e) {
    return defaultValue;
  }
};

const isCookiesDebugOn = getSafe(() => process.env.IS_COOKIES_DEBUG_ON);

const isTestOrStaging = getSafe(
  () => window.location.hostname.toString().indexOf("test") !== -1 || window.location.hostname.toString().indexOf("staging") !== -1
);

export const setCookie = (cName, cValue, expDays = 365, prefix = "lndck") => {
  try {
    let cookie, cookiePart;
    if (expDays > 0) {
      const date = new Date();
      date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
      const expires = `expires=${date.toUTCString()}`;
      cookiePart = `${prefix}-${cName.toLowerCase()}=${cValue}; ${expires}; path=/`;
    } else {
      cookiePart = `${prefix}-${cName.toLowerCase()}=${cValue}; path=/`;
    }
    cookie = window.location.hostname.toString().indexOf("lendwise.com") !== -1 ? `${cookiePart}; domain=.lendwise.com` : cookiePart;
    if (!isCookiesDebugOn && isTestOrStaging) {
      cookie = cookiePart;
    }
    document.cookie = cookie;
  } catch (err) {
    console.error(`Error in setCookie. Error: ${err.message}`);
  }
};

export const getCookie = (cName, prefix = "lndck") => {
  try {
    const name = `${prefix}-${cName.toLowerCase()}=`;
    const cDecoded = decodeURIComponent(document.cookie);
    const cArr = cDecoded.split("; ");
    var res;
    cArr.forEach(function(val) {
      if (val.indexOf(name) === 0) {
        res = val.substring(name.length);
      }
    });
    return res;
  } catch (err) {
    console.error(`Error in getCookie. Error: ${err.message}`);
    return undefined;
  }
};

export const parseBoolean = val => (!val ? false : getSafe(() => JSON.parse(val.toLowerCase())));

export const isCookiesEnabled = () => {
  let cookieEnabled = navigator.cookieEnabled ? true : false;
  if (typeof navigator.cookieEnabled === "undefined" && !cookieEnabled) {
    document.cookie = "lndck-test-cookie-enabled";
    cookieEnabled = document.cookie.indexOf("lndck-test-cookie-enabled") !== -1 ? true : false;
  }

  return cookieEnabled;
};

export const reducePayload = (payload, mapper = elem => elem) => {
  if (!payload) {
    return payload;
  }

  if (payload.constructor !== Array || payload.length === 0) {
    return payload;
  }
  if (payload.length === 1) {
    return { [payload[0].id]: mapper(payload[0]) };
  }

  return payload.reduce((acc, elem, index) => {
    if (index === 1) {
      return { [acc.id]: mapper(acc), [elem.id]: mapper(elem) };
    }
    return { ...acc, [elem.id]: mapper(elem) };
  });
};

export const updateReduxFormField = (form, field, payload, touch = false, persistentSubmitErrors = false, callback = undefined) => dispatch => {
  try {
    const update = () => {
      dispatch({
        type: "@@redux-form/CHANGE",
        meta: {
          form,
          field,
          touch,
          persistentSubmitErrors
        },
        payload
      });
    };

    return new Promise(resolve => {
      update();
      return resolve();
    }).then(() => {
      if (callback) {
        return callback();
      }
    });
  } catch (err) {
    console.error(err);
  }
};

export const isEmptyArray = arr => Array.isArray(arr) && arr.length === 0;

export const isNotEmptyArray = arr => Array.isArray(arr) && arr.length > 0;

export const isEmptyObject = obj => getSafe(() => Object.keys(obj).length === 0 && obj.constructor === Object);

export const toTitleCase = str =>
  !str
    ? str
    : str
        .toLowerCase()
        .split(" ")
        .map(elem => elem.charAt(0).toUpperCase() + elem.slice(1))
        .join(" ");

export const toQueryString = obj => {
  if (!obj) {
    return "";
  }
  const str = Object.keys(obj)
    .map(key => {
      if (!Array.isArray(obj[key])) {
        return key + "=" + obj[key];
      }
      return obj[key].map(elem => key + "=" + elem).join("&");
    })
    .join("&");
  return "?" + str;
};

export const isValueNotSet = value => value === "undefined" || value === undefined || value === null;

export const getCookieWrapper = (name, field) => {
  try {
    const cookie = getCookie(CookieTypes[field]);
    return { [name]: isValueNotSet(cookie) ? false : parseBoolean(cookie) };
  } catch (e) {
    console.error(`Failed at getCookieWraper. Error: ${e.message}`);
  }
};

export const isCookieEnabled = () => {
  try {
    if (navigator.cookieEnabled) {
      return true;
    }

    // set and read a dummy cookie
    document.cookie = "isCookieEnabled=1";
    const ret = document.cookie.indexOf("isCookieEnabled=") !== -1;

    // delete cookie
    document.cookie = "isCookieEnabled=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";

    return ret;
  } catch (e) {
    console.error(`Failed at getCookieWraper. Error: ${e.message}`);
    return false;
  }
};
